<template>
  <v-expansion-panel v-model="panel" class="mt-2">
    <v-expansion-panel-header>
      Reporte Calculo Inventarios
      <template v-slot:actions>
        <v-icon color="primary" @click.native.stop="reload()">
          mdi-refresh
        </v-icon>
        <v-icon
          color="error"
          @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })"
        >
          mdi-close
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-tabs v-model="tab" dark background-color="primary" icons-and-text >
        <v-tabs-slider color="red"></v-tabs-slider>
        <v-tab href="#tab-1">
          Promedio Ponderado
          <v-icon>mdi-archive-search</v-icon>
        </v-tab>
        <v-tab href="#tab-2">
          PEPS
          <v-icon>mdi-table-check</v-icon>
        </v-tab>
        <template>
        <v-tab href="#tab-3">
          UEPS
          <v-icon>mdi-table-minus</v-icon>
        </v-tab>
        </template>
      </v-tabs>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1">
          <v-card flat class="pa-5 position-relative">
            <v-card-text> 
              <WeightedAverage :win="win" :key="key"  :datafilter="filterData" />

            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card flat class="pa-5 position-relative">
            <v-card-text> 
              <Peps :win="win" :key="key" :datafilter="filterData" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-3">
          <v-card flat class="pa-5 position-relative">
            <v-card-text> 
              <Ueps :win="win" :key="key" :datafilter="filterData" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-expansion-panel-content>
    <!--product-data v-if="showProduct"
                              :key="showProduct.data.ID_Item"
                              @reloadData="reloadProducts"
                              :product="showProduct.data"
                              :win="win"
                  ></product-data-->
  </v-expansion-panel>
</template>

<script>
//import DatePicker from "../../../components/DatePicker";

import WeightedAverage from "@/views/inventory/reports/WeightedAverage";
import Peps from "@/views/inventory/reports/Peps";    
import Ueps from "@/views/inventory/reports/Ueps";
//import ProductData from "@/views/inventory/mainPages/ManageProduct";
export default {
  name: "InventoryCalculationReport",
  components: {
    WeightedAverage,
    Peps,
    Ueps,
    //DatePicker,
    //ProductData,
  },
  props: ["win"],
  data() {
    return {
      panel: 0,
      tab: "tab-1",
      key: 0,
      filterData: {
        Families: [],
        Categories: [],
        SubCategories: [],
        Warehouses: [],
        batches: [],
        providers: [],
      },
    };
  },
  methods:{
    async getFilterData() {
      try {
        const { businessid = "", branch = "" } = JSON.parse(localStorage.getItem("user"));
        const filtersWh = { business: businessid, branch };
       

        const [families, categories, subs, warehouses, batches, providers] = await Promise.all([
          this.$API.itemtypes.getFamilies(),
          this.$API.itemtypes.getCategories(),
          this.$API.itemtypes.getSubs(),
          this.$API.warehouse.getWarehouses(filtersWh),
          this.$API.products.getAllBatch(),
          this.$API.providers.getProvidersHistory(filtersWh)
        ]);
        console.log(batches)
        this.filterData.Families = families.map((fam) => ({name: fam.name, value: fam.id}));
        this.filterData.Categories = categories.map((cat) => ({name: cat.name, value: cat.id}));
        this.filterData.SubCategories = subs.map((sub) => ({name: sub.name, value: sub.id}));
        this.filterData.Warehouses = warehouses.map((wh) =>({name: wh.Warehouse, value: wh.ID_Warehouse}));
        this.filterData.batches = batches.map(({ID_ItemBatch}) => ({name: ID_ItemBatch, value: ID_ItemBatch}));
        this.filterData.providers = providers.map((prov) => ({name: prov.ProviderName, value: prov.ID_Provider}));
        this.key++;
      } catch (error) {
        // Manejar errores aquí
        console.error("Error al obtener datos:", error);
      }
    },
    reload(){
      this.key++;
      this.getFilterData();

    }
    
  },
  mounted(){
    this.getFilterData();
  }
};
</script>

<style scoped>
.show .v-list-item {
  min-height: auto !important;
}

.filter-btn {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.justified-column {
  width: 100%;
  border: 1px solid #ccc;
  /* Solo para visualizar el contenedor */
  padding: 10px;
  /* Espaciado interno */
  word-wrap: break-word;
  /* Permite el salto de palabras */
}
</style>
